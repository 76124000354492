import { faBuilding, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useOpenClose from "@helper/custom-hooks/use-open-close";
import { completeSearch } from "@helper/search/search-api-helper";
import SearchStore from "@helper/search/search-store";
import { XIcon } from "@heroicons/react/solid";
import { debounce } from "lodash";
import { useRouter } from "next/router";
import React, { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { searchIcon } from "styles/icons";

import SearchFilterDropdown from "./search-filter-dropdown";

type Suggestion = {
    name: string;
    username: string;
    type: string;
};
const Searchbar: FC = () => {
    const history = localStorage.getItem("searchHistory");
    const [storage, setStorage] = useState<string[]>(history ? JSON.parse(history) : []);
    const setSearchValue = SearchStore((state) => state.setSearchValue);
    const router = useRouter();
    const [inputValue, setInputValue] = useState("");
    const [writing, setWriting] = useState("");
    const [cursor, setCursor] = useState(0);
    const box = useOpenClose();
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const reference = useRef(null);
    const { t } = useTranslation();
    const inputElement = useRef(null);
    const searchValue = SearchStore((state) => state.searchValue);
    const filterType = SearchStore((state) => state.filterType);
    const setFilterType = SearchStore((state) => state.setFilterType);
    const debouncedSearch = useRef(
        debounce(async (value) => {
            try {
                const result = await completeSearch(value);
                setSuggestions(result);
            } catch (e) {
                console.error(e);
            }
        }, 300),
    ).current;

    const handleClickOutside = (event: { target: any }) => {
        // @ts-ignore
        if (reference.current && !reference.current.contains(event.target)) {
            setCursor(-1);
            box.handleClose();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });
    useEffect(() => {
        setInputValue(searchValue);
    }, [searchValue]);
    const goToSearch = (value: string, username?: string, type?: string) => {
        const newElement = username ? JSON.stringify({ name: value, username, type }) : value;
        const newLocalStorage = [newElement, ...storage.filter((e) => e !== newElement)];
        localStorage.setItem("searchHistory", JSON.stringify(newLocalStorage));
        setStorage(newLocalStorage);
        setCursor(-1);
        setInputValue(value);
        box.handleClose();
        if (username) {
            return router.push(`/profile/${type === "user" ? "info" : "company"}/${username}`);
        }
        if (value && value.trim() !== "") {
            setSearchValue(value);
            router.push(`/search?query=${encodeURIComponent(value)}`);
        }
    };

    const keyHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.keyCode === 38 && cursor === 0) {
            e.preventDefault();
            setInputValue(writing);
            setCursor(-1);
        }
        if (e.keyCode === 38 && cursor > 0) {
            e.preventDefault();
            if (writing) setInputValue(suggestions[cursor - 1].name);
            else setInputValue(storage[cursor - 1]);

            setCursor(cursor - 1);
        }
        if (e.keyCode === 40 && !writing && cursor < 6) {
            e.preventDefault();
            setInputValue(storage[cursor + 1]);
            setCursor(cursor + 1);
        } else if (suggestions && e.keyCode === 40 && cursor < suggestions.length - 1) {
            e.preventDefault();
            setInputValue(suggestions[cursor + 1]?.name);
            setCursor(cursor + 1);
        }
        if (e.key === "Enter") {
            goToSearch(inputValue);
        }
    };
    // Set focus to input when ctrl+f is pressed
    useHotkeys("ctrl+space", () => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    });

    const removeHistory = (value: string) => {
        const temporary = storage.filter((e) => e !== value);
        localStorage.setItem("searchHistory", JSON.stringify(temporary));
        setStorage(temporary);
    };

    const onSearch = async (value: string) => {
        if (value !== writing) {
            box.handleOpen();
            setCursor(-1);
            setWriting(value);
            setInputValue(value);
            if (value.replaceAll(" ", "")) {
                debouncedSearch(value);
            } else {
                setSuggestions([]);
            }
        }
    };

    const renderSearchHistory = (
        <>
            <div className="w-full p-2 text-xs">
                <span>Search History</span>
                {storage?.length > 6 && <div className="float-right cursor-pointer border-b border-rc-pink hover:text-rc-pink">See All</div>}
            </div>
            {storage
                .slice(0, 6)
                .map((value: string, index) => {
                    if (value?.includes("username")) {
                        const suggestion = JSON.parse(value);
                        const { username, name, type } = suggestion;
                        if (!name) return null;
                        return (
                            <div key={name} className={` relative cursor-pointer hover:bg-rc-pink ${cursor === index ? "bg-rc-pink" : ""}`}>
                                <div className=" flex w-full justify-between p-2 text-white" onClick={() => goToSearch(name, username, type)}>
                                    {name}
                                    <FontAwesomeIcon icon={type === "user" ? faUser : faBuilding} className="mr-2 pr-3" />
                                </div>
                                <XIcon
                                    className="absolute right-2 top-1/2 z-20 mx-auto size-4 -translate-y-1/2"
                                    onClick={() => removeHistory(value)}
                                />
                            </div>
                        );
                    }
                    if (!value) return null;
                    return (
                        <div
                            key={`${value}-${index}`}
                            className={` relative cursor-pointer hover:bg-rc-pink ${cursor === index ? "bg-rc-pink" : ""}`}
                        >
                            <div className=" w-full p-2 text-white" onClick={() => goToSearch(value)}>
                                {value}
                            </div>
                            <XIcon className="absolute right-2 top-1/2 z-20 mx-auto size-4 -translate-y-1/2" onClick={() => removeHistory(value)} />
                        </div>
                    );
                })
                .filter(Boolean)}
        </>
    );

    const formatSuggestionStart = (searchString: string, wholeString: string): string => {
        if (wholeString.toLowerCase().startsWith(searchString.toLowerCase())) {
            return searchString;
        }

        return "";
    };

    const formatSuggestionEnd = (searchString: string, wholeString: string): string => {
        if (wholeString.toLowerCase().startsWith(searchString.toLowerCase())) {
            return wholeString.slice(searchString.length);
        }

        return wholeString;
    };

    const renderSearchStrings = () => {
        const withoutProfileResults = suggestions?.filter((suggestion) => !suggestion.type);
        const withProfiles = suggestions?.filter((suggestion) => suggestion.type === "user" || suggestion.type === "company");

        return (
            <>
                {withoutProfileResults && withoutProfileResults.length > 0 && (
                    <>
                        {withoutProfileResults.map((suggestion, index) => {
                            const { name } = suggestion;
                            if (!name) return null;
                            return (
                                <div
                                    onClick={() => goToSearch(name)}
                                    key={`${name}-${index}`}
                                    className={`group flex w-full cursor-pointer justify-between p-2 text-white hover:bg-rc-pink ${
                                        cursor === index ? "bg-rc-pink" : ""
                                    }`}
                                >
                                    <div>
                                        {formatSuggestionStart(writing, name)}
                                        <span className={`font-bold ${cursor !== index ? "text-rc-pink" : ""} group-hover:text-white`}>
                                            {formatSuggestionEnd(writing, name)}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </>
                )}
                {withProfiles && withProfiles.length > 0 && (
                    <>
                        <hr />
                        <div className="w-full p-2 pb-0 text-xs">
                            <span>Realclixx Results</span>
                        </div>
                        {withProfiles.map((suggestion, index) => {
                            const { name, username, type } = suggestion;
                            if (!name) return null;
                            return (
                                <div
                                    onClick={() => goToSearch(name, username, type)}
                                    key={`${name}-${index}`}
                                    className={`group flex w-full cursor-pointer justify-between p-2 pt-0 text-white hover:bg-rc-pink ${
                                        cursor === index ? "bg-rc-pink" : ""
                                    }`}
                                >
                                    <div>
                                        {formatSuggestionStart(writing, name)}
                                        <span className={`font-bold ${cursor !== index ? "text-rc-pink" : ""} group-hover:text-white`}>
                                            {formatSuggestionEnd(writing, name)}
                                        </span>
                                    </div>
                                    {username && <FontAwesomeIcon icon={type === "user" ? faUser : faBuilding} className="pr-3" />}
                                </div>
                            );
                        })}
                    </>
                )}
            </>
        );
    };

    return (
        <div ref={reference}>
            <div className="flex text-white">
                <SearchFilterDropdown />

                <div className="relative w-full">
                    <input
                        ref={inputElement}
                        onFocus={box.handleOpen}
                        onKeyDown={keyHandler}
                        value={inputValue}
                        onChange={(e) => onSearch(e.target.value)}
                        className="h-8 w-full rounded-l-md bg-rc-black-light pl-3  text-sm text-white outline-0 placeholder:text-white"
                    />
                    {filterType && (
                        <div className="mt-2 flex gap-3 ">
                            <div
                                className={`cursor-pointer rounded-md border border-rc-pink px-1 text-xs text-rc-pink`}
                                key={filterType.name}
                                onClick={() => {
                                    setFilterType(undefined);
                                }}
                            >
                                <div className="flex">
                                    <span className="p-1">{t(filterType.name)}</span>
                                    <span className="my-auto">
                                        <XIcon className="size-3" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                    {box.isOpen && (
                        <div className="absolute w-full bg-rc-black ">
                            {writing !== "" ? renderSearchStrings() : storage && storage.length > 0 ? renderSearchHistory : <></>}
                        </div>
                    )}
                </div>
                <div className=" relative size-8 cursor-pointer rounded-r-md bg-rc-pink  px-3 py-1 hover:hover:text-white">
                    <button
                        onClick={() => goToSearch(inputValue)}
                        className="absolute  left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                        type="submit"
                    >
                        <>{searchIcon("#fff", "h-4 w-4")}</>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Searchbar;
