import { IFilterLabel, SearchMultimediaPostType } from "@helper/search-filter";
import create from "zustand";
import { devtools } from "zustand/middleware";

interface SearchStoreState {
    searchValue: string;
    filterType: IFilterLabel;
    hiddenFilterType: IFilterLabel;
    setHiddenFilterType: (filterType: IFilterLabel) => void;
    setSearchValue: (searchValue: string) => void;
    setFilterType: (filterType: IFilterLabel) => void;
    searchSelectedMultimediaFilterNetworks: string[];
    setSearchSelectedMultimediaFilterNetworks: (networks: string[]) => void;
    searchSelectedMultimediaFilterPostTypes: SearchMultimediaPostType[];
    setSearchSelectedMultimediaFilterPostTypes: (postTypes: SearchMultimediaPostType[]) => void;
    toggleSearchNetwork: (network: string) => void;
    toggleSearchPostType: (postType: SearchMultimediaPostType) => void;
}

const SearchStore = create<SearchStoreState>(
    devtools(
        (set) => ({
            searchValue: "",
            filterType: undefined,
            hiddenFilterType: undefined,
            setHiddenFilterType: (hiddenFilterType: IFilterLabel) => set(() => ({ hiddenFilterType })),
            setSearchValue: (searchValue: string) => set(() => ({ searchValue })),
            setFilterType: (filterType: IFilterLabel) => set(() => ({ filterType })),
            searchSelectedMultimediaFilterNetworks: [],
            setSearchSelectedMultimediaFilterNetworks: (networks: string[]) => set(() => ({ searchSelectedMultimediaFilterNetworks: networks })),
            searchSelectedMultimediaFilterPostTypes: [],
            setSearchSelectedMultimediaFilterPostTypes: (postTypes: SearchMultimediaPostType[]) =>
                set(() => ({ searchSelectedMultimediaFilterPostTypes: postTypes })),
            toggleSearchNetwork: (network) => {
                set((state) => {
                    const networks = [...state.searchSelectedMultimediaFilterNetworks];
                    state.setSearchSelectedMultimediaFilterNetworks(
                        networks?.includes(network) ? networks.filter((n) => n !== network) : [...networks, network],
                    );
                });
            },
            toggleSearchPostType: (postType) => {
                set((state) => {
                    const postTypes = [...state.searchSelectedMultimediaFilterPostTypes];
                    state.setSearchSelectedMultimediaFilterPostTypes(
                        postTypes?.includes(postType) ? postTypes.filter((n) => n !== postType) : [...postTypes, postType],
                    );
                });
            },
        }),
        { name: "SearchStore" },
    ),
);

export default SearchStore;
