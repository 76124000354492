import Button from "@components/buttons/button";
import useSignUp from "@helper/custom-hooks/use-sign-up";
import { useRouter } from "next/dist/client/router";
import React, { FC } from "react";

const SignUpNetworks: FC = () => {
    const router = useRouter();
    const { openClose, setStep, clearUserState } = useSignUp();

    return (
        <div className="w-screen px-14 md:mx-auto xl:w-full xl:px-0">
            <div className="flex flex-col items-center py-16 ">
                <span className="font-headline text-3xl text-white">Welcome To Our System!</span>
                <span className="text-center text-xl font-light text-white">You Can to Start Using Today</span>
            </div>
            <Button
                title="Start"
                onClick={() => {
                    router.push("/social");
                    setStep(0);
                    openClose.handleClose();
                    clearUserState();
                }}
            />
        </div>
    );
};

export default SignUpNetworks;
